.headerNav {
    min-height: 80px;
    /* overflow: hidden; */
    background-color: rgb(252, 252, 252);
}
.logoRol * {
    padding: 5px;
}
.rol {
    font-size: 20px;
    margin-left: 15px;
    color: blue;
}
.linksHeader:hover {
    background-color: aliceblue !important;
}
