* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Sofia Sans', sans-serif;
}
#root {
    max-width: 100vw;
    overflow-x: hidden;
}
body {
    background-color: #ffffff !important;
}

/* animaciones generales  */

.top,
.right,
.left,
.bottom,
.appear {
    opacity: 0;
}

.animation-top {
    opacity: 0;
    animation: top 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.animation-left {
    opacity: 0;
    animation: left 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes left {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.animation-right {
    opacity: 0;
    animation: right 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes right {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.animation-bottom {
    opacity: 0;
    animation: bottom 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.animation-appear {
    opacity: 0;
    animation: appear 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
