.containerTotalContact {
    min-height: 100vh;
}
.contactoParrafo {
    font-size: 2.5rem;
}
.buttonContact {
    font-size: 1.9rem;
}
.letTalk {
    font-size: 3rem;
    font-weight: 700;
}
.develomentFor {
    font-size: 2rem;
    color: blue;
}
.buttonUp {
    position: fixed;
    display: none;
    top: 90%;
    left: 94%;
    padding: 5px;
}

.buttonUp:hover {
    scale: 1.1;
}
.buttonUpImg {
    opacity: 0.7;
}
@media (max-width: 560px) {
    .buttonUpImg {
        width: 29px;
        height: 29px;
        opacity: 0.7;
    }
    .buttonUp {
        position: fixed;
        display: none;
        top: 90%;
        left: 85%;
        padding: 5px;
    }
    .develomentFor {
        font-size: 1rem;
        color: blue;
    }
    .contactoParrafo {
        font-size: 2rem;
    }
}
